import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
 faFacebook,
 faGithub,
 faLinkedin,
} from '@fortawesome/fontawesome-free-brands'
import Link from './Link'
import logo from '../../assets/images/zaven_logo_rgb_color_neg.png'
import pwrLogo from '../../assets/images/logos/pwr-logo.png'
import fdiLogo from '../../assets/images/logos/FDI-logo.png'
import eeaLogo from '../../assets/images/logos/EEA-logo.png'
 
class Footer extends React.Component {
 render() {
   const { websiteVersion, companyDetails } = this.props
 
   return (
     <footer className="footer bg-dark">
       <div className="container">
         <div className="row pb-5">
           <div className="col">
             <h3 className="seo-h1">
             Zaven är ett Software House i polska Wroclaw. Ta reda på mer:
             </h3>
           </div>
         </div>
       </div>
       <div className="container">
         <div className="row pb-3">
           <div className="col-sm-6 col-md-4 col-lg-3 pb-4 pb-md-0">
             <div className="logo-wrapper d-flex align-items-center">
               <img src={pwrLogo} className="footer-logo" alt="PWR" />
             </div>
             <p className="mt-3">
               årligen 2500 utexaminerade från<br/>
               IT-relaterade skolor with IT annual
             </p>
           </div>
           <div className="col-sm-6 col-md-4 col-lg-3 pb-4 pb-md-0">
             <div className="logo-wrapper d-flex align-items-center">
               <img src={fdiLogo} className="footer-logo" alt="FDI" />
             </div>
             <p className="mt-3">
               3 plats i Polish Cities of<br/>
               the Future 2019/20
             </p>
           </div>
           <div className="col-sm-6 col-md-4 col-lg-3 pb-4 pb-md-0">
             <div className="logo-wrapper d-md-flex align-items-md-center">
               <img src={eeaLogo} className="footer-logo eea-logo" alt="EE Awards" />
             </div>
             <p className="mt-3">
               Den bästa strategin för<br/>
               att skaffa utländskt investeringskapital
             </p>
           </div>
           <div className="col-sm-6 col-md-12 col-lg-3 pt-md-3 pt-lg-0">
             <hr className="d-sm-none d-md-block d-lg-none" />
             <div className="text-lg-right text-left">
               <p className="lead">
                 <Link to="/" className="zaven-brand">
                   <img src={logo} className="zaven-logo" alt="Zaven" />
                 </Link>
               </p>
               <address>
                 <a
                   href={`${companyDetails.address.office.url}`}
                   target="_blank"
                   className="link-unstyled"
                   rel="noopener noreferrer"
                 >{`${companyDetails.address.office.street}, ${
                   companyDetails.address.office.city
                   } (${companyDetails.address.office.country})`}</a>
                 <br />
                 <span className="d-none d-md-inline">
                   <a
                     href="mailto:hello@zaven.co"
                     className="link-unstyled"
                   >{`${companyDetails.contact.email}`}</a>
                   ,{' '}
                   <a
                     href={`tel:${companyDetails.contact.phoneClean}`}
                     className="link-unstyled"
                   >{`${companyDetails.contact.phone}`}</a>
                 </span>
               </address>
               <p className="footer-social">
                 <a
                   href={companyDetails.linkedin}
                   target="_blank"
                   rel="noopener noreferrer nofollow"
                 >
                   <FontAwesomeIcon
                     icon={faLinkedin}
                     style={{ margin: '0.15rem' }}
                   />
                 </a>
                 <a
                   href={companyDetails.facebook}
                   target="_blank"
                   rel="noopener noreferrer nofollow"
                 >
                   <FontAwesomeIcon
                     icon={faFacebook}
                     style={{ margin: '0.15rem' }}
                   />
                 </a>
                 <a
                   href={companyDetails.github}
                   target="_blank"
                   rel="noopener noreferrer nofollow"
                 >
                   <FontAwesomeIcon
                     icon={faGithub}
                     style={{ margin: '0.15rem' }}
                   />
                 </a>
               </p>
             </div>
           </div>
         </div>
         <hr />
         <div className="row">
           <div className="col-sm-6">
             <div className="row">
               <div className="col-md-3">
                 <nav className="nav d-none d-md-block">
                   <Link to="/">
                     Ingångssida
 
                   </Link>
                   <br />
                   <Link to="/projects/">Projekt</Link>
                   <br />
                   <a
                     href="/blog/"
                   >
                     Blogg
                   </a>
                   <br />
                 </nav>
               </div>
               <div className="col-md-3">
                 <nav className="nav d-block">
                   <p className='d-none d-md-inline'>
                     <Link to="/career/">
                       Karriär
                     </Link>
                     <br />
                   </p>
                   <p className='d-none d-md-inline'>
                     <Link to="/contact/">Kontakt</Link>
                     <br />
                   </p>
                   <Link to="/legal/">Villkor</Link>
                   <br />
                   <Link to="/gdpr/">DSF</Link>
                   <br />
                   <p className='d-none d-md-inline'>
                     <Link to="/services/mobile-app-development-poland/">
                       Mobilappar
                     </Link>
                     <br/>
                   </p>
 
                   <p className='d-none d-md-inline'>
                     <Link to="/services/web-app-development-poland/">
                       Webbappar
                     </Link>
                     <br />
                   </p>
 
                     <p className='d-none d-md-inline'>
                       <Link to="/services/poland-software-outsourcing/">
                         Outsourca IT
                       </Link>
                     </p>
 
                 </nav>
               </div>
               <div className="col-md-6">
                 <nav className="nav d-block mt-2 mt-md-0">
                   <a href="https://zaven.co/">
                     English
                   </a>
                   <br />
                   <a href="https://es.zaven.co">
                     Español
                   </a>
                   <br />
                   <a href="https://de.zaven.co">
                     Deutsch
                   </a>
                   <br />
                   <a href="https://se.zaven.co">
                     Svenska
                   </a>
                   <br />
                   <a href="https://nl.zaven.co">
                     Nederlands
                   </a>
                   <br />
                   <a href="https://fr.zaven.co">
                     Français
                   </a>
                   <br />
                 </nav>
               </div>
             </div>
           </div>
           <div className="col-md-6">
             <p className="footer-legal text-left text-md-right">
               v{websiteVersion} © All rights reserved.{' '}
               {companyDetails.legalName}, {companyDetails.address.base.street}
               , {companyDetails.address.base.postal}{' '}
               {companyDetails.address.base.city}, VAT-ID {companyDetails.vat}{' '}
               Reg no. {companyDetails.krs}, {companyDetails.krs_sad}, share
               capital {companyDetails.capital}. Apple Watch® and Swift® are
               trademarks of Apple Inc.
             </p>
           </div>
         </div>
       </div>
     </footer>
   )
 }
}
 
export default Footer
